<template>
  <div>
    <v-select
      v-model="selected_value"
      :placeholder="placeholder"
      :options="computedOptions"
      label="text"
      multiple
      class="text-white"
      :reduce="(option) => option.value"
    />
  </div>
</template>

<script>

import { orderBy } from 'lodash'

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Select Industry',
    },
    options: {
      type: [Array, Object],
      default: () => {
        return []
      },
    },
    selectedValue: {
      type: [String, Array, Object],
      default: null,
    }
  },

  data() {
    return {
      selected_value: null,
    }
  },

  computed: {
    computedOptions() {
      let options = []

      if (this.$isNotEmpty(this.options)) {
        options = this.options
      }

      options = orderBy(options, ['text'], ['asc'])

      return options
    },
  },

  watch: {
    selected_value(newValue, oldValue) {
      this.$emit('update', newValue)
    },
    selectedValue(newValue, oldValue) {
      this.selected_value = this.selectedValue
    },
  },

  mounted() {
    this.selected_value = this.selectedValue
  },

  methods: {
    //
  },
}
</script>

<style>

</style>